// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _021020252100030720251600Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_021020252100030720251600() {
  return React.useContext(_021020252100030720251600Context);
}

export default _021020252100030720251600Context;
/* prettier-ignore-end */
