/*
  IMPORTANT NOTE:
  This file is modified by AST at build time.
*/
import React, {createContext, memo} from 'react';
import PropTypes from 'prop-types';
import CtaLevel from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__CtaLevel';
import TestExpAbCd3Fg1 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__TestExpAbCd3Fg1';
import _010119700000123129992359 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___010119700000123129992359';
import Region from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__Region';
import Country from '../plasmic_components/plasmic/placeit_landing_pages/PlasmicGlobalVariant__Country';
import _102920241500110120240500 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___102920241500110120240500';
import _112720241500120420240500 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___112720241500120420240500';
import C1RWdyOnVipExbhYAf from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__C1RWdyOnVipExbhYAf';
import _010920251500021020252050 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___010920251500021020252050';
import _121620241500122620240500 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___121620241500122620240500';
import _122620240501010120250600 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___122620240501010120250600';
import _021020252100030720251600 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___021020252100030720251600';
// insert imports

// in addition to the variant contexts needed by plasmic... we can use this
// single variant context to hold all experiments... easier to use in our code.
export const VariantContext = createContext({
  variations: {},
  setVariations: async () => null,
});
const PlasmicVariants = memo(
  class extends React.Component {
    render() {
      if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
        console.log('PlasmicVariants Render', Date.now());
      return (
        <VariantContext.Provider value={this.props}>
          <_021020252100030720251600.Provider value={this.props._021020252100030720251600}>
            <_122620240501010120250600.Provider value={this.props._122620240501010120250600}>
              <_121620241500122620240500.Provider value={this.props._121620241500122620240500}>
                <_010920251500021020252050.Provider value={this.props._010920251500021020252050}>
                  <C1RWdyOnVipExbhYAf.Provider value={this.props.c1rwdyonvipexbhyaf}>
                    <_112720241500120420240500.Provider
                      value={this.props._112720241500120420240500}
                    >
                      <_102920241500110120240500.Provider
                        value={this.props._102920241500110120240500}
                      >
                        <Country.Provider value={this.props.country}>
                          <Region.Provider value={this.props.region}>
                            <_010119700000123129992359.Provider
                              value={this.props._010119700000123129992359}
                            >
                              <TestExpAbCd3Fg1.Provider value={this.props.testexpabcd3fg1}>
                                <CtaLevel.Provider value={this.props.ctalevel}>
                                  {this.props.children}
                                </CtaLevel.Provider>
                              </TestExpAbCd3Fg1.Provider>
                            </_010119700000123129992359.Provider>
                          </Region.Provider>
                        </Country.Provider>
                      </_102920241500110120240500.Provider>
                    </_112720241500120420240500.Provider>
                  </C1RWdyOnVipExbhYAf.Provider>
                </_010920251500021020252050.Provider>
              </_121620241500122620240500.Provider>
            </_122620240501010120250600.Provider>
          </_021020252100030720251600.Provider>
        </VariantContext.Provider>
      );
    }
  }
);
PlasmicVariants.propTypes = {
  children: PropTypes.node.isRequired,
};
export default PlasmicVariants;
export const ContextNames = [
  'ctalevel',
  'testexpabcd3fg1',
  '_010119700000123129992359',
  'region',
  'country',
  '_102920241500110120240500',
  '_112720241500120420240500',
  'c1rwdyonvipexbhyaf',
  '_010920251500021020252050',
  '_121620241500122620240500',
  '_122620240501010120250600',
  '_021020252100030720251600',
];
